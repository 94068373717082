import '/assets/styles/pages/checkout-ticketselection.scss';
import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import classNames from 'classnames';

// Functions
import formatPrice from '/functions/format-price';
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';
import isExact from '@wearetla/tla-essentials-tools/functions/is-exact';

// Hooks
import useDebounce from '@wearetla/tla-essentials-tools/hooks/debounce';

// Partials
import { Form, Input } from '/views/partials/forms';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import Loader from '/views/partials/loader';
import Btn from '/views/partials/btn';
import Pricebox from '/views/partials/pricebox';

// Context
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useParams, useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useGlobalEvents } from '@wearetla/tla-essentials-tools/utilities/global-events';
import { useGlobalState } from "@wearetla/tla-essentials-tools/utilities/global-state";

// Services
import eventServices from '/services/event';
import bookingServices from '/services/booking';

const parseHead = (event) => {
	if(event?.calendar?.full_date) {
		const eventDate = formatDate(event.calendar.full_date, 'd MMMM');
		return {
			title: `Bilet Seçin - ${event.title} / ${eventDate} ${event.calendar.event_location}`,
			description: `${eventDate} tarihli ${event.calendar.event_location} ${event.title} etkinliği için bilet alın.`,
			image: event.image?.asset_paths?.original,
		}
	}
	
	return {
		title: 'Bilet Seçin',
	}
}

const CheckoutTicketSelection = () => {
	const { setHead } = useHead();
	const { triggerEvent } = useGlobalEvents();
	const { setNotFound } = useGlobalState();
	const { loggedIn } = useAuth();
	const { openModal } = useModals();
	const { mobile } = useBreakpoints();
	const { redirect } = useNavigator();

	const params = useParams(['checkout']);

	const [event, setEvent] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [rawTickets, setRawTickets] = useState([]);
	const syncedTicketsRef = useRef(null);

	const getSycnedTicketRefData = () => {
		try {
			const parsedData = JSON.parse(window.localStorage.getItem(process.config.checkout.cartPersistenceKey));
	
			if(typeof parsedData === 'object' && Array.isArray(parsedData)) {
				return parsedData;
			}
	
		}
		catch(e) {
			console.log('Warning: Invalid synced tickets ref.');
		}
		return [];
	};

	const setSyncedTicketsRef = (newData) => {
		syncedTicketsRef.current = newData;
		window.localStorage.setItem(process.config.checkout.cartPersistenceKey, JSON.stringify(newData));
	}

	const emptyTicket = useMemo(() => {
		if(event) {

			const singleOption = event?.calendar?.options?.length === 1 ? event.calendar.options[0] : false;

			return {
				option: singleOption ? singleOption.id : null,
				slot: singleOption?.slots?.length === 1 ? singleOption?.slots[0].id : null,
			}
		}
		else {
			return null;
		}
	}, [event])

	const eventParams = useMemo(() => ({ eventId: params.id, calendarId: params.calendarId }), [params]);

	const debouncedEventParams = useDebounce(eventParams, 150, null);

	const { tickets, ticketAddable, summary } = useMemo(() => {
		if(!event) {
			return {
				tickets: [],
				ticketAddable: false,
			}
		}

		const selectedSlots = (rawTickets ?? []).reduce((selectedSlots, rawTicket) => {
			if(rawTicket.slot) {
				return [
					...selectedSlots,
					rawTicket.slot
				];
			}
			return selectedSlots;
		}, []);

		let productTotal = 0;
		let summaryItems = [];
		let serviceFee = 0;

		const tickets = (rawTickets).map((rawTicket) => {
			var selectedOption = undefined;
			const calendarOptions = event.calendar.options.map(option => {
				const rOption = {
					title: option.title,
					label: `${option.title} (${formatPrice(option.price)} TL)`,
					value: option.id,
					rawOption: option,
					price: option.price,
					serviceFee: option.service_fee,
				}

				if(rOption.value === rawTicket.option) {
					selectedOption = rOption;
				}

				return rOption;
			});

			var selectedSlot = undefined;
			const optionSlots = selectedOption ? selectedOption.rawOption.slots.map(slot => {
				const rSlot = {
					label: slot.title,
					value: slot.id,
					rawSlot: slot,
					isDisabled: (slot.is_multiple_buy !== true && selectedSlots.includes(slot.id)),
				}

				if(rSlot.value === rawTicket.slot) {
					selectedSlot = rSlot;
				}

				return rSlot;
			}) : undefined;

			if(selectedSlot) {
				productTotal += selectedOption.price;
				serviceFee += selectedOption.serviceFee;
				summaryItems.push({
					title: `${selectedOption.title} - ${selectedSlot.label}`,
					price: selectedOption.price,
				})
			}

			return {
				option: {
					disabled: false,
					options: calendarOptions,
					value: selectedOption,
				},
				slot: {
					disabled: !selectedOption,
					options: optionSlots,
					value: selectedSlot,
				},
			}
		});

		return {
			tickets,
			ticketAddable: selectedSlots.length === rawTickets.length,
			summary: {
				productTotal,
				serviceFee,
				total: productTotal + serviceFee,
				items: summaryItems,
			},
		}
		

	}, [rawTickets, event]);

	// Functions
	const updateTicket = useCallback((index, key, value) => {
		if(rawTickets[index][key] !== value) {
			setRawTickets(rawTickets.map((ticket, nth) => ({
				...ticket,
				...((index === nth) ? {
					[key]: value,
				} : {}),
			})));
		}
	}, [rawTickets]);

	const addTicket = useCallback(() => {
		setRawTickets([
			...rawTickets,
			{
				slot: undefined,
				...emptyTicket,
				...rawTickets[rawTickets.length -1],
			},
		]);
	}, [rawTickets, emptyTicket]);

	const deleteTicket = useCallback((index) => {
		setRawTickets(rawTickets.filter((t, nth) => nth !== index));
	}, [rawTickets]);

	const submit = () => {
		if(loggedIn) {
			setSubmitting(true);
	
			bookingServices.prepareBooking(
				event.id,
				event.calendar.id,
				rawTickets.map(t => ({ id: t.slot}))
			).then((payload) => {
				window.localStorage.setItem(process.config.checkout.salePersistenceKey, payload.id);

				triggerEvent('beginCheckout', { sale: payload });
				redirect('payment');
			}).catch((feedback) => {
				openModal('message', { feedback });
				setSubmitting(false);
			});
		}
		else {
			openModal('auth');
		}
	}

	useEffect(() => {
		if(debouncedEventParams) {
			const { eventId, calendarId } = debouncedEventParams;
			if(eventId && calendarId) {
				window.localStorage.removeItem(process.config.checkout.salePersistenceKey);
				if((event?.id.toString() !== eventId.toString()) || event?.calendar?.id?.toString?.() !== calendarId) {
					eventServices.getEvent(eventId).then(payload => {
						const foundCalendar = payload.calendars?.find?.((c) => (c.id.toString() === calendarId));
	
						if(foundCalendar) {
							if(foundCalendar.buy_btn_link) {
								redirect(foundCalendar.buy_btn_link, {}, { raw: true, hard: true });
							}
							else {
								window.localStorage.setItem(process.config.checkout.checkoutEventPersistenceKey, eventId);
								window.localStorage.setItem(process.config.checkout.checkoutCalendarPersistenceKey, calendarId);
								setEvent({
									...(omit(payload, ['calendars'])),
									calendar: foundCalendar,
									dateTimeString: formatDate(foundCalendar.full_date, 'dd.MM.y - HH:mm')
								});
							}
						}
						else {
							window.localStorage.removeItem(process.config.checkout.checkoutEventPersistenceKey);
							window.localStorage.removeItem(process.config.checkout.checkoutCalendarPersistenceKey);
							openModal('message', { message: 'Bilet bulunamadı' });
							redirect('home');
						}
	
					}).catch(() => {
						setNotFound();
					})
				}
			}
			else {
				const storageEventId = window.localStorage.getItem(process.config.checkout.checkoutEventPersistenceKey);
				const storageCalendarId = window.localStorage.getItem(process.config.checkout.checkoutCalendarPersistenceKey);
	
				if(storageEventId && storageCalendarId) {
					redirect('checkout', { id: storageEventId, calendarId: storageCalendarId });
				}
				else {
					openModal('message', { message: 'Bilet bulunamadı' });
					redirect('home');
				}
			}
		}
	}, [event, debouncedEventParams]);

	useEffect(() => {
		if(emptyTicket && rawTickets.length === 0) {
			setRawTickets([emptyTicket]);
		}
	}, [emptyTicket, rawTickets])

	// Auto Select if only one slot available
	useEffect(() => {
		const lastTicketIndex = tickets.length - 1;
		const lastTicket = tickets[lastTicketIndex];
		if(lastTicket?.option?.value && lastTicket.slot?.options?.length === 1 && !lastTicket.slot.value) {
			setTimeout(() => {
				updateTicket(lastTicketIndex, 'slot', lastTicket.slot.options[0].value);
			}, 30)

		}
		else if(lastTicketIndex === 0 && params.optionId &&  lastTicket?.option && !lastTicket.option.value) {
			const matchedOption = lastTicket.option.options.find(o => o.value.toString() === params.optionId);

			if(matchedOption) {
				setTimeout(() => {
					updateTicket(lastTicketIndex, 'option', matchedOption.value);
				}, 30)
			}
			else {
				redirect('checkout', omit(params, ['optionId'], { replace: true, force: true }));
			}
		}
	}, [tickets, params])

	useEffect(() => {
		if(!syncedTicketsRef.current) {
			syncedTicketsRef.current = getSycnedTicketRefData();
		}

		const simplifiedTickets = tickets.filter(t => t.option?.value).map((t => ({
			...t.option.value,
		})));

		if(event?.calendar && !isExact(syncedTicketsRef.current, simplifiedTickets)) {
			try {
				const addedTickets = syncedTicketsRef.current.reduce((addedTickets, curTicket) => {
					const foundIndex = addedTickets.findIndex(t => t.value === curTicket.value);
	
					return addedTickets.filter((t, index) => index !== foundIndex);
				}, simplifiedTickets);
				
				const removedTickets = simplifiedTickets.reduce((removedTickets, curTicket) => {
					const foundIndex = removedTickets.findIndex(t => t.value === curTicket.value);
	
					return removedTickets.filter((t, index) => index !== foundIndex);
				}, syncedTicketsRef.current);

				if(addedTickets?.length) {
					triggerEvent('addToCart', { event, options: addedTickets.map(t => t.rawOption) });
				}
				if(removedTickets?.length) {
					triggerEvent('removeFromCart', { event, options: removedTickets.map(t => t.rawOption) });
				}
			}
			catch(e) {
				console.error(e);
			}

			setSyncedTicketsRef(simplifiedTickets);
		}
	}, [tickets, event])

	useEffect(() => {
		if(event) {
			setHead(parseHead(event));
		}
	}, [event])

	return (
		<Form
			onSubmit={submit}
			className="layout-checkout-content wrapper loader-container">
			<Loader dark loading={!(event && rawTickets.length)} />
			{!!event &&
				<>
					<div className="checkout-maincontent">
						<main className="checkout-section">
							<div className="checkout-section-header">
								<h1 className="header-title">Bilet Seçimi</h1>
							</div>

							{!!event.calendar.hero_message?.length &&
								<div className="ticketselection-heromessage">
									{event.calendar.hero_message}
								</div>
							}

							{!!event.calendar?.options?.length &&
								<>
									<div className="ticketselection-tickets">
										{tickets.map((ticket, nth) => (
											<div className="tickets-ticket" key={nth}>
												<Input
													className="ticket-option"
													name={`ticket-${nth}-option`}
													label="Grup Seçimi"
													z={((tickets.length - nth) * 2) + 1}
													value={ticket.option.value}
													disabled={submitting || ticket.option.disabled}
													options={ticket.option.options}
													validation={{
														required: 'Grup seçmelisiniz.'
													}}
													onChange={(option) => {
														updateTicket(nth, 'option', option.value);
													}}
													type="select" />
												<Input
													className="ticket-slot"
													name={`ticket-${nth}-slot`}
													label="Blok"
													z={(tickets.length - nth) * 2}
													value={ticket.slot.value}
													disabled={submitting || ticket.slot.disabled}
													options={ticket.slot.options}
													validation={{
														required: 'Blok seçmelisiniz.'
													}}
													onChange={(option) => {
														updateTicket(nth, 'slot', option.value);
													}}
													type="select" />
												{(tickets.length !== 1 || !mobile) &&
													<Btn
														disabled={tickets.length === 1}
														className={classNames('ticket-removebtn', 'text', 'small', { block: mobile })}
														onClick={() => { deleteTicket(nth); }}
														icon="trash">
														{mobile &&
															<>Bu Bileti İptal Et</>
														}
													</Btn>
												}
											</div>
										))}
									</div>

									<Btn
										className={classNames('ticketselection-addbtn', 'big', 'primary', { block: mobile })}
										icon="plus"
										onClick={addTicket}
										disabled={submitting || !ticketAddable}>
										Bilet Ekle
									</Btn>
								</>
							}

							{/* {event.ticketInfo?.notes && 
								<div
									className="ticketselection-notes"
									dangerouslySetInnerHTML={{__html: event.ticketInfo?.notes}} />
							} */}
						</main>
					</div>
									
					<aside className="checkout-aside">
						<div className="aside-info">
							<div className="info-event">
								<Img
									className="event-image"
									src={event.image?.asset_paths?.thumbnail} />

								<div className="event-text">
									<strong className="event-title">{event.title}</strong>

									<div className="event-field">
										<Icon name="map-pin" /> {event.calendar.event_location}
									</div>

									<div className="event-field">
										<Icon name="map-pin" /> {event.dateTimeString}
									</div>
								</div>
							</div>

							{summary.items.length > 0 &&
								<>
									<div className="info-tickets">
										<strong className="tickets-title">Biletleriniz:</strong>
										<ul className="tickets-list">
											{summary.items.map((item, nth) => (
												<li className="tickets-ticket" key={nth}>
													<span className="ticket-title">
														{item.title}
													</span>
													<Pricebox
														className="ticket-price"
														price={item.price} />
												</li>
											))}
										</ul>
									</div>

									<ul className="info-prices">
										<li className="prices-item">
											<span>Bilet Fiyatı</span>
											<Pricebox className="item-price" price={summary.productTotal} />
										</li>
										<li className="prices-item">
											<span>İşlem Ücreti</span>
											<Pricebox className="item-price" price={summary.serviceFee} />
										</li>
										<li className="prices-item total">
											<span>Toplam</span>
											<Pricebox className="item-price" price={summary.total} />
										</li>
									</ul>
								</>
							}
						</div>

						<div className="aside-controls">
							<Btn
								type="submit"
								loading={submitting}
								disabled={!ticketAddable}
								className="controls-cta block big primary">
								Güvenli Ödeme Adımına Geç
							</Btn>
						</div>
					</aside>
				</>
			}
		</Form>
	)
}

CheckoutTicketSelection.propTypes = {
	pageProps: PropTypes.object
}

export default CheckoutTicketSelection